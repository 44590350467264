import { select as d3Select, event as d3Event } from 'd3-selection';
import { scaleLinear, scaleSqrt } from 'd3-scale';
import { hierarchy as d3Hierarchy, partition as d3Partition } from 'd3-hierarchy';
import { arc as d3Arc } from 'd3-shape';
import { path as d3Path } from 'd3-path';
import { interpolate as d3Interpolate } from 'd3-interpolate';
import { transition as d3Transition } from 'd3-transition';

// import * as data from '../devices.json';

import Kapsule from 'kapsule';
import accessorFn from 'accessor-fn';



const TRANSITION_DURATION = 750;
const CHAR_PX = 6;



export default Kapsule({


    props: {
        width: { default: window.innerWidth },
        height: {
            default: window.innerHeight,
            onChange: function() {
                //console.log(window.innerWidth,window.innerHeight) 
                // for responsive purpose...
                if (window.innerWidth / window.innerHeight < 1) {
                    window.innerHeight = window.innerWidth
                } else {
                    window.innerHeight = window.innerHeight
                }

            }
        },
        scenario: { default: "devices" },
        titleImg: { default: "img/devices.svg" },
        data: { onChange: function() { this._parseData(); } },
        children: { default: 'children', onChange: function() { this._parseData(); } },
        sort: { onChange: function() { this._parseData(); } },
        label: { default: d => d.name },
        size: {
            default: 'value',
            onChange: function() { this._parseData(); }
        },
        color: { default: d => 'lightgrey' },
        minSliceAngle: { default: .2 },
        showLabels: { default: true },
        tooltipContent: { default: d => '', triggerUpdate: false },
        detailName: { default: d => '', triggerUpdate: false },
        detailTagline: { default: d => '', triggerUpdate: false },
        detailContentTitle: { default: d => '', triggerUpdate: false },
        detailContent: { default: d => '', triggerUpdate: false },
        detailExampleTitle: { default: d => '', triggerUpdate: false },
        detailExample: { default: d => '', triggerUpdate: false },
        detailScenariosTitle: { default: d => '', triggerUpdate: false },
        detailScenarios: { default: d => '', triggerUpdate: false },
        detailNeedsTitle: { default: d => '', triggerUpdate: false },
        detailNeeds: { default: d => '', triggerUpdate: false },
        detailRelatedLinks: { default: d => '', triggerUpdate: false },
        detailRelatedVideo: { default: d => '', triggerUpdate: false },
        detailAvailable: { default: d => '', triggerUpdate: false },
        focusOnNode: {
            onChange: function(d, state) {
                if (d && state.initialised) {
                    moveStackToFront(d.__dataNode);
                }



                function moveStackToFront(elD) {
                    state.svg.selectAll('.slice').filter(d => d === elD)
                        .each(function(d) {
                            this.parentNode.appendChild(this);
                            if (d.parent) { moveStackToFront(d.parent); }
                        })
                }
            }
        },
        onClick: { triggerUpdate: false }
    },

    methods: {
        _parseData: function(state) {
            if (state.data) {
                const hierData = d3Hierarchy(state.data, accessorFn(state.children))
                    .sum(accessorFn(state.size));

                // console.log('hierData', hierData.data);


                if (state.sort) {
                    hierData.sort(state.sort);
                    // hierData.sort((a, b) => b.value - a.value);
                }

                d3Partition().padding(0)(hierData);

                hierData.descendants().forEach((d, i) => {
                    // console.log(d.data.__dataNode)
                    d.id = i; // Mark each node with a unique ID
                    d.data.__dataNode = d; // Dual-link data nodes
                });

                state.layoutData = hierData.descendants();

                // console.log('hierData after', hierData.data);

            }
        }
    },

    aliases: {
        onNodeClick: 'onClick'
    },

    init: function(domNode, state) {

        state.chartId = Math.round(Math.random() * 1e12); // Unique ID for DOM elems

        state.radiusScale = scaleSqrt();
        // state.radiusScale = scaleLinear();
        state.angleScale = scaleLinear()
            .domain([0, 20]) // For initial build-in animation
            .range([0, 2 * Math.PI])
            .clamp(true);

        state.arc = d3Arc()
            .startAngle(d => state.angleScale(d.x0))
            .endAngle(d => state.angleScale(d.x1))
            .innerRadius(d => Math.max(0, state.radiusScale(d.y0)))
            .outerRadius(d => Math.max(0, state.radiusScale(d.y1)));


        const el = d3Select(domNode).append('div').attr('class', 'sunburst-viz');

        state.svg = el.append('svg')
        state.canvas = state.svg.append('g');

        state.imgContainer = state.svg.append("g").attr("id", "img-container");


        // tooltips
        state.tooltip = d3Select('body')
            .append('div')
            .attr('class', 'sunburst-tooltip2');

        state.tooltipBox = d3Select('#detail-box')


        state.tooltipBoxRecommendation = state.tooltipBox.append('span').attr('class', 'recommended').html('Recommended');
        state.tooltipBoxHeader = state.tooltipBox.append('h3')
        state.tooltipBoxTagline = state.tooltipBox.append('h4')
        state.tooltipBoxMetaTitle = state.tooltipBox.append('p').attr('class', 'description-title').append('strong')
        state.tooltipBoxMeta = state.tooltipBox.append('p').attr('class', 'description')

        state.tooltipBoxExampleTitle = state.tooltipBox.append("p").attr('class', 'title').append('a').attr('class', 'example-title')
        state.tooltipBoxExample = state.tooltipBox.append('ul').attr('class', 'example')

        state.tooltipBoxScenariosTitle = state.tooltipBox.append("p").attr('class', 'title').append('a').attr('class', 'scenarios-title')
        state.tooltipBoxScenarios = state.tooltipBox.append('ul').attr('class', 'scenarios')

        state.tooltipBoxNeedsTitle = state.tooltipBox.append("p").attr('class', 'title').append('a').attr('class', 'needs-title')
        state.tooltipBoxNeeds = state.tooltipBox.append('ul').attr('class', 'needs')

        state.tooltipBoxRelatedLinks = state.tooltipBox.append('p').attr('class', 'related-link')
        state.tooltipBoxRelatedVideo = state.tooltipBox.append('p').attr('class', 'related-video')
        state.tooltipBoxAvailable = state.tooltipBox.append('div').attr('class', 'available')


        state.tooltipBoxHeader.html('Welcome on this MEP IT Support tool by ').append('span').html(state.scenario)


        // tooltip cleanup on unmount
        domNode.addEventListener('DOMNodeRemoved', function(e) {
            if (e.target === this) {
                state.tooltip.remove();
                state.tooltipBox.remove();
            }
        });

        // expandable example 
        $('.example-title').click(function(e) {
            e.stopPropagation();
            $(this).toggleClass("open");
            $('.example').slideToggle('fast');
        });
        // expandable scenarios 
        $('.scenarios-title').click(function(e) {
            e.stopPropagation();
            $(this).toggleClass("open");
            $('.scenarios').slideToggle('fast');
        });
        // expandable needs 
        $('.needs-title').click(function(e) {
            e.stopPropagation();
            $(this).toggleClass("open");
            $('.needs').slideToggle('fast');
        });

        $('.related-link').click(function(e) {
            e.stopPropagation();
        });
        // Avoid reset focus by clicking on detail box
        $('#detail-box').click(function(e) {
            e.stopPropagation();
        });






        // Reset focus by clicking outside
        d3Select('body').on('click', () => {
            (state.onClick || this.focusOnNode)(null);
            state.svg.select("#img-container").attr("class", "image-center visible");


            d3Select('.available').html('');
            d3Select('.related-link').html('');
            d3Select('.related-video').html('');
            state.tooltipBoxMetaTitle.html('');
            state.tooltipBoxMeta.html('');
            state.tooltipBoxTagline.html('');
            state.tooltipBoxHeader.html('Welcome on this MEP IT Support tool by ').append('span').html(state.scenario)
            state.tooltipBoxRecommendation.style('display', 'none')
            state.tooltipBoxExampleTitle.html('').style('display', 'none');
            state.tooltipBoxExample.html('');
            state.tooltipBoxScenariosTitle.html('').style('display', 'none');
            state.tooltipBoxScenarios.html('');
            state.tooltipBoxNeedsTitle.html('').style('display', 'none');
            state.tooltipBoxNeeds.html('');
        });






    },

    update: function(state) {
        const maxRadius = (Math.min(state.width, state.height) / 2);
        state.radiusScale.range([maxRadius * .1, maxRadius]);

        state.svg
            .style('width', state.width + 'px')
            .style('height', state.height + 'px')
            .attr('viewBox', `${-state.width / 2} ${-state.height / 2} ${state.width} ${state.height}`);

        d3Select('#chart').style('height', state.height + 'px')

        $('#img-container').html("");
        d3Select('#strokedCircle').remove();
        d3Select('#strokedCircleSmall').remove();

        state.svg.append('circle').attr('id', 'strokedCircle').attr('cx', '0').attr('cy', '0').attr('r', maxRadius + 15);

        let imgSize = maxRadius - 200;

        if (imgSize < 40) {

            imgSize = 60;
        }

        d3Select('li#devices')
            .style("cursor", "pointer")
            .on('click', () => {
                resetAll(state);
                loadData('json/devices.json?v3', 'img/devices.svg', 'devices');
                d3Select('li#devices').classed("active", true);
            });

        d3Select('li#networks')
            .style("cursor", "pointer")
            .on('click', () => {
                resetAll(state);
                loadData('json/networks.json?v3', 'img/network.svg', 'networks');
                d3Select('li#networks').classed("active", true);
            });

        d3Select('li#services')
            .style("cursor", "pointer")
            .on("click", () => {
                resetAll(state)
                loadData('json/services.json?v4', 'img/services.svg', 'services');
                d3Select('li#services').classed("active", true);
            });


        d3Select('li#reset').style("cursor", "pointer").on("click", () => {
            (state.onClick || this.focusOnNode)(null);
            state.svg.select("#img-container").attr("class", "image-center visible");
            state.tooltipBoxRecommendation.style('display', 'none')
        });

        state.imgContainer.append("svg:image")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", imgSize)
            .attr("height", imgSize)
            .attr("xlink:href", state.titleImg)
            .attr("transform", "translate(" + (-imgSize / 2) + "," + (-imgSize / 2) + ")")
            .attr("class", "image-center");


        if (!state.layoutData) return;

        const focusD = (state.focusOnNode && state.focusOnNode.__dataNode) || { x0: 0, x1: 1, y0: 0, y1: 1 };

        const slice = state.canvas.selectAll('.slice')
            .data(
                state.layoutData
                .filter(d => // Show only slices with a large enough angle

                    d.x1 >= focusD.x0 &&
                    d.x0 <= focusD.x1 &&
                    (d.x1 - d.x0) / (focusD.x1 - focusD.x0) > state.minSliceAngle / 360

                ),
                d => d.id
            );

        const nameOf = accessorFn(state.label);
        const colorOf = accessorFn(state.color);
        const transition = d3Transition().duration(TRANSITION_DURATION);

        // Apply zoom
        state.svg.transition(transition)
            .tween('scale', () => {
                const xd = d3Interpolate(state.angleScale.domain(), [focusD.x0, focusD.x1]);
                const yd = d3Interpolate(state.radiusScale.domain(), [focusD.y0, 1]);

                return t => {
                    state.angleScale.domain(xd(t));
                    state.radiusScale.domain(yd(t));
                };
            })
            .on("end", function() {
                // console.log('zoom complete')
                if (!state.focusOnNode && d3Select('#strokedCircleSmall').empty()) {
                    state.svg.append('circle').attr('id', 'strokedCircleSmall').attr('cx', '0').attr('cy', '0').attr('r', (maxRadius / 2) - 18);
                }

                if (state.focusOnNode && state.focusOnNode.__dataNode.depth === 0 && d3Select('#strokedCircleSmall').empty()) {
                    state.svg.append('circle').attr('id', 'strokedCircleSmall').attr('cx', '0').attr('cy', '0').attr('r', (maxRadius / 2) - 18);
                }

            });



        // Exiting
        const oldSlice = slice.exit().transition(transition).style('opacity', 0).remove();
        oldSlice.select('path.main-arc').attrTween('d', d => () => state.arc(d));
        oldSlice.select('path.hidden-arc').attrTween('d', d => () => middleArcLine(d));




        // Given a node in a partition layout, return an array of all of its ancestor
        // nodes, highest first, but excluding the root.
        function getAncestors(node) {
            var path = [];
            var current = node;
            while (current.parent) {
                path.unshift(current);
                current = current.parent;
            }
            return path;
        }


        function mouseover(d) {

            d3Select('.available').html('');
            d3Select('.related-link').html('');
            d3Select('.related-video').html('');
            d3Select('.tooltip-title').html('');


            if (d.data.color === "#F5F5F5" || d.data.color === "#f5f5f5") {

                state.tooltip.style('display', 'inline');
                state.tooltip.html(`No solutions through this scenario`);

                state.tooltipBox.style('display', 'block');
                // state.tooltipBoxHeader.html(`Oups!`);
                // state.tooltipBoxTagline.html(`Sorry...`);
                // state.tooltipBoxMeta.html(`No solutions through this scenario.`);

                state.tooltipBoxHeader.html('Welcome on this MEP IT Support tool by ').append('span').html(state.scenario)
                state.tooltipBoxTagline.html('');
                state.tooltipBoxMeta.html('');

                state.tooltipBoxRecommendation.style('display', 'none')
                state.tooltipBoxExampleTitle.html('').style('display', 'none');
                state.tooltipBoxExample.html('');
                state.tooltipBoxScenariosTitle.html('').style('display', 'none');
                state.tooltipBoxScenarios.html('');
                state.tooltipBoxNeedsTitle.html('').style('display', 'none');
                state.tooltipBoxNeeds.html('');
                state.tooltipBoxMetaTitle.html('');


                return

            } else {

                var sequenceArray = getAncestors(d);
                // highlight only those that are an ancestor of the current segment.
                state.canvas.selectAll("path")
                    .filter(function(node) {
                        return (sequenceArray.indexOf(node) >= 0);
                    })
                    .style("opacity", 0.7);

                state.tooltip.style('display', 'inline');
                state.tooltip.html(`<div class="tooltip-title">${
          getNodeStack(d).map(d => nameOf(d.data)).join(' / ')
          }</div>${state.tooltipContent(d.data, d)}`);

                state.tooltipBox.style('display', 'block');

                state.tooltipBoxHeader.html(`${state.detailName(d.data, d)}`);
                state.tooltipBoxTagline.html(`${state.detailTagline(d.data, d)}`);

                if (state.detailContentTitle(d.data, d) !== '') {
                    state.tooltipBoxMetaTitle.html(`${state.detailContentTitle(d.data, d)}`);
                } else {
                    state.tooltipBoxMetaTitle.html('');
                }

                state.tooltipBoxMeta.html(`${state.detailContent(d.data, d)}`);

                if (state.detailExampleTitle(d.data, d) !== '') {
                    state.tooltipBoxExampleTitle.html(`${state.detailExampleTitle(d.data, d)}`).style('display', 'inline-block');
                } else {
                    state.tooltipBoxExampleTitle.style('display', 'none');
                }
                state.tooltipBoxExample.html(`${state.detailExample(d.data, d)}`)

                if (state.detailScenariosTitle(d.data, d) !== '') {
                    state.tooltipBoxScenariosTitle.html(`${state.detailScenariosTitle(d.data, d)}`).style('display', 'inline-block');
                } else {
                    state.tooltipBoxScenariosTitle.style('display', 'none');
                }
                if (state.detailScenariosTitle(d.data, d) !== '') {
                    state.tooltipBoxScenarios.html(`${state.detailScenarios(d.data, d)}`)
                } else {
                    state.tooltipBoxScenarios.style('display', 'none');
                }
                if (state.detailNeedsTitle(d.data, d) !== '') {
                    state.tooltipBoxNeedsTitle.html(`${state.detailNeedsTitle(d.data, d)}`).style('display', 'inline-block');
                } else {
                    state.tooltipBoxNeedsTitle.style('display', 'none');
                }
                state.tooltipBoxNeeds.html(`${state.detailNeeds(d.data, d)}`);

                if (state.detailRelatedLinks(d.data, d) !== '') {
                    state.detailRelatedLinks(d.data, d).forEach((item, i) => {
                        item[0].forEach((item, i) => {
                            console.log(item[0], i)
                            state.tooltipBoxRelatedLinks.append("a").attr("href", item[1]).attr("target", "_blank").text(item[0])
                                // (`<a href="${item[0]}" target="_blank">${item[1]}</a>`);
                        })
                    })
                }

                if (state.detailRelatedVideo(d.data, d) !== '') {
                    state.tooltipBoxRelatedVideo.html(`<a href="${state.detailRelatedVideo(d.data, d)}" target="_blank">Link to the video</a>`);
                }

                if (state.detailAvailable(d.data, d)) {
                    state.tooltipBoxAvailable.html(`${state.detailAvailable(d.data, d)}`);
                }

                if (d.data.recommended === "1") {
                    state.tooltipBoxRecommendation.style('display', 'block')
                } else {
                    state.tooltipBoxRecommendation.style('display', 'none')
                }
            }


            // close all accordion
            $('.example-title, .scenarios-title, .needs-title').removeClass("open");
            $('.example, .scenarios, .needs').slideUp(0);


        }

        function mouseleave(d) {
            // Transition each segment to full opacity and then reactivate it.
            state.canvas.selectAll("path")
                .style("opacity", 1)
                .on("end", function() {
                    //console.log('out')
                });
            //

            // close all accordion
            $('.example-title, .scenarios-title, .needs-title').removeClass("open");
            $('.example, .scenarios, .needs').slideUp(0);



            // state.tooltip.style('display', 'none');
            // state.tooltipBox.style('display', 'none');
            // d3Select('.available').html('');
            // d3Select('.related-link').html('');
            // d3Select('.related-video').html('');

            // state.tooltipBoxMetaTitle.html('');



        }



        // Entering
        const newSlice = slice.enter().append('g')
            .attr('class', 'slice')
            .style('opacity', 0)
            .on('click', d => {

                if (d.data.color === "#F5F5F5" || d.data.color === "#f5f5f5") {
                    console.log("click disabled for this item")
                    d3Event.stopPropagation();
                    return
                }

                if (d.depth > 0) {
                    state.svg.select("#img-container").attr("class", "image-center")
                    d3Select('#strokedCircleSmall').remove();
                } else if (d.depth === 0) {
                    state.svg.select("#img-container").attr("class", "image-center visible")
                    state.svg.append('circle').attr('id', 'strokedCircleSmall').attr('cx', '0').attr('cy', '0').attr('r', (maxRadius / 2) - 18);
                }


                d3Event.stopPropagation();
                (state.onClick || this.focusOnNode)(d.data);
            })
            .on('mouseover', d => {
                //
                mouseover(d);
                //
            })
            .on('mouseout', () => {
                //
                mouseleave()
                    //
            })




        newSlice.append('path').attr('class', 'main-arc').style('fill', d => colorOf(d.data, d.parent));



        newSlice.append('path')
            .attr('class', 'hidden-arc')
            .attr('id', d => `hidden-arc-${state.chartId}-${d.id}`);

        const label = newSlice.append('text')
            .attr('class', 'path-label');

        // Add white contour
        // label.append('textPath')
        //   .attr('class', 'text-contour')
        //   .attr('startOffset', '50%')
        //   .attr('xlink:href', d => `#hidden-arc-${state.chartId}-${d.id}`);

        label.append('textPath')
            .attr('class', 'text-stroke')
            .attr('startOffset', '50%')
            .attr('xlink:href', d => `#hidden-arc-${state.chartId}-${d.id}`);

        // Entering + Updating
        const allSlices = slice.merge(newSlice);

        allSlices.style('opacity', 1);

        state.canvas.selectAll("path").style("opacity", 1);


        allSlices.select('path.main-arc').transition(transition)
            .attrTween('d', d => () => state.arc(d))
            .style('fill', d => colorOf(d.data, d.parent))
            .style('stroke-width', d => {
                if (d.data.color === "#FAFAFA" || d.data.color === "#fafafa") {
                    return '0'
                }
            });



        allSlices.select('path.hidden-arc').transition(transition)
            .attrTween('d', d => () => middleArcLine(d));

        allSlices.select('.path-label')
            .transition(transition)
            .styleTween('display', d => () => state.showLabels && textFits(d) ? null : 'none');

        // Ensure propagation of data to children
        allSlices.selectAll('text.path-label').select('textPath.text-contour');
        allSlices.selectAll('text.path-label').select('textPath.text-stroke');
        allSlices.selectAll('text.path-label').selectAll('textPath').text(d => nameOf(d.data));


        function middleArcLine(d) {

            const halfPi = Math.PI / 2;
            const angles = [state.angleScale(d.x0) - halfPi, state.angleScale(d.x1) - halfPi];
            const r = Math.max(0, (state.radiusScale(d.y0) + state.radiusScale(d.y1)) / 2);

            if (!r || !(angles[1] - angles[0])) return '';

            const middleAngle = (angles[1] + angles[0]) / 2;
            const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
            if (invertDirection) { angles.reverse(); }

            const path = d3Path();
            path.arc(0, 0, r, angles[0], angles[1], invertDirection);
            return path.toString();
        }

        function textFits(d) {
            const deltaAngle = state.angleScale(d.x1) - state.angleScale(d.x0);
            const r = Math.max(0, (state.radiusScale(d.y0) + state.radiusScale(d.y1)) / 2);
            const perimeter = r * deltaAngle;
            return nameOf(d.data).toString().length * CHAR_PX < perimeter;
        }

        function getNodeStack(d) {
            const stack = [];
            let curNode = d;
            while (curNode) {
                stack.unshift(curNode);
                curNode = curNode.parent;
            }
            return stack;
        }

        // remove img, clipPath, text to avoid 'append' repetition when resizing
        // d3.select('image').remove();
        // d3.select('defs').remove();

    }

});

function resetAll(state) {

    d3Select('.sunburst-viz').remove();
    d3Select('.sunburst-tooltip2').remove();
    state.svg.remove();
    state.tooltip.html('');
    state.tooltipBox.html('');
    state.tooltipBoxHeader.html('');
    state.tooltipBoxTagline.html('');
    state.tooltipBoxMetaTitle.html('');
    state.tooltipBoxMeta.html('');
    state.tooltipBoxRelatedLinks.html('');
    state.tooltipBoxRelatedVideo.html('');
    state.tooltipBoxAvailable.html('');
    state.tooltipBoxRecommendation.style('display', 'none')

    d3Select('li#networks').classed("active", false);
    d3Select('li#services').classed("active", false);
    d3Select('li#devices').classed("active", false);
}

var myChart;



function getData(dataset, titleImg, scenario) {

    console.log(dataset)

    myChart = Sunburst()
        .data(dataset)
        .width(window.innerWidth * 0.75)
        .height(window.innerHeight * 0.75)
        .scenario(scenario)
        .titleImg(titleImg)
        .size('size')
        .color('color')
        .showLabels(true)
        .tooltipContent((d, node) => `<span>${node.value} equipment and solutions</span>`)
        .detailName((d, node) => `${d.name ? d.name : ""}`)
        .detailTagline((d, node) => `<i>${d.tagline ? d.tagline : ""}</i>`)
        .detailContentTitle((d, node) => `${d.descriptionTitle ? d.descriptionTitle : ""}`)
        .detailContent((d, node) => `${d.description ? d.description : ""}`)
        .detailExampleTitle((d, node) => `${d.examplesTitle ? d.examplesTitle : ""}`)
        .detailExample((d, node) => `${d.examples ? d.examples.map((item, i) => `<li>${item}</li>`).join('') : ""}`)
          .detailScenariosTitle((d, node) => `${d.scenariosTitle ? d.scenariosTitle : ""}`)
          .detailScenarios((d, node) => `${d.scenarios ? d.scenarios.map((item, i) => `<li>${item}</li>`).join('') : ""}`)
          .detailNeedsTitle((d, node) => `${d.needsTitle ? d.needsTitle : ""}`)
          .detailNeeds((d, node) => `${d.needs ? d.needs.map((item, i) => `<li>${item}</li>`).join('') : ""}`)
         
          .detailRelatedLinks((d, node) => {
            if(d.relatedLinks){
              var linksList = new Map();
              Object.keys(d).forEach(function(key) {
                if(key === 'relatedLinks'){
                  linksList.set(d[key]);
                }
              })
              linksList = Array.from(linksList);
              return linksList;
            } else {
              return "";
            }
            
          })

          .detailRelatedVideo((d, node) => `${d.relatedVideo ? d.relatedVideo : ""}`)
          .detailAvailable((d, node) => {
              var devicesList = new Map();
              Object.keys(d).forEach(function(key) {
                  if(key === 'mobile' || key === 'tablet' || key === 'desktop' || key === 'hybrid'){
                      devicesList.set(key, d[key] === '1' ? 'on' : '');
                  }
              })
              devicesList = Array.from(devicesList);
              function devicesHighlight(devicesList) { 
                  if(devicesList.length > 0){
                      return `<p>Available on:</p><ul class='available-list'>${devicesList.map((item, i) => `<li id='${item[0]}' class='${item[1]}'></li>`).join('')}</ul>`
                  } else {
                      return null;
                  }
              }
              return devicesHighlight(devicesList);
          })
        (document.getElementById('chart'));
    
  }

function loadData(dataset, titleImg, scenario){
  
  fetch(dataset)
    .then(res => res.json())
    .then(data => {
      console.log( data)
      myChart = Sunburst()
          .data(data)
          .width(window.innerWidth*0.75)
          .height(window.innerHeight*0.75)
          .scenario(scenario)
          .titleImg(titleImg)
          .size('size')
          .color('color')
          .showLabels(true)
          .tooltipContent((d, node) => `<span>${node.value} equipment and solutions</span>`)
          .detailName((d, node) => `${d.name ? d.name : ""}`)
          .detailTagline((d, node) => `<i>${d.tagline ? d.tagline : ""}</i>`)
          .detailContentTitle((d, node) => `${d.descriptionTitle ? d.descriptionTitle : ""}`)
          .detailContent((d, node) => `${d.description ? d.description : ""}`)
          .detailExampleTitle((d, node) => `${d.examplesTitle ? d.examplesTitle : ""}`)
          .detailExample((d, node) => `${d.examples ? d.examples.map((item, i) => `<li>${item}</li>`).join('') : ""}`)
          .detailScenariosTitle((d, node) => `${d.scenariosTitle ? d.scenariosTitle : ""}`)
          .detailScenarios((d, node) => `${d.scenarios ? d.scenarios.map((item, i) => `<li>${item}</li>`).join('') : ""}`)
          .detailNeedsTitle((d, node) => `${d.needsTitle ? d.needsTitle : ""}`)
          .detailNeeds((d, node) => `${d.needs ? d.needs.map((item, i) => `<li>${item}</li>`).join('') : ""}`)
         
          .detailRelatedLinks((d, node) => {
            if(d.relatedLinks){
              var linksList = new Map();
              Object.keys(d).forEach(function(key) {
                if(key === 'relatedLinks'){
                  linksList.set(d[key]);
                }
              })
              linksList = Array.from(linksList);
              return linksList;
            } else {
              return "";
            }
            
          })

          .detailRelatedVideo((d, node) => `${d.relatedVideo ? d.relatedVideo : ""}`)
          .detailAvailable((d, node) => {
              var devicesList = new Map();
              Object.keys(d).forEach(function(key) {
                  if(key === 'mobile' || key === 'tablet' || key === 'desktop' || key === 'hybrid'){
                      devicesList.set(key, d[key] === '1' ? 'on' : '');
                  }
              })
              devicesList = Array.from(devicesList);
              function devicesHighlight(devicesList) { 
                  if(devicesList.length > 0){
                      return `<p>Available on:</p><ul class='available-list'>${devicesList.map((item, i) => `<li id='${item[0]}' class='${item[1]}'></li>`).join('')}</ul>`
                  } else {
                      return null;
                  }
              }
              return devicesHighlight(devicesList);
          })
        (document.getElementById('chart'));
    });
  }

  loadData('json/devices.json?v3', 'img/devices.svg', 'devices');
  // setTimeout(() => getData(data, 'img/devices.svg', 'devices'), 20);  

  window.addEventListener("resize", function() { 
    myChart.width(window.innerWidth*0.75).height(window.innerHeight*0.75);
  });